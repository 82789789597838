// Remove Focus Boxes
select:focus {
  outline: $select-focus;
}

button:focus {
  outline: none;
  background-color: $button-background-focus;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */ 
  color: #333333;
  font-size:0.8rem;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #333333;
  font-size:0.8rem;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #333333;
  font-size:0.8rem;
}

:-moz-placeholder { /* Firefox 18- */
  color: #333333;
  font-size:0.8rem;
}

/*  Gravity Form Styles */
form, .gform_wrapper {

  label, .gfield_label {
        font-size: 0.9rem;
  }

  &.hide-labels, .hide-labels {

      labels, .gfield_label  {
          display:none;
      }

  }


  .validation_error, .validation_message {
      font-size: 0.8rem;
      color:$validation-text-color;
          margin-bottom: 8px;
  }

  .gfield_required {   color:$validation-text-color; margin-left: 5px; }

  .gfield_error {

      input:not([type]),
      input[type=text]:not(.browser-default),
      input[type=password]:not(.browser-default),
      input[type=email]:not(.browser-default),
      input[type=url]:not(.browser-default),
      input[type=time]:not(.browser-default),
      input[type=date]:not(.browser-default),
      input[type=datetime]:not(.browser-default),
      input[type=datetime-local]:not(.browser-default),
      input[type=tel]:not(.browser-default),
      input[type=number]:not(.browser-default),
      input[type=search]:not(.browser-default),
      textarea { 
          border-color:$validation-text-color;
      }

  }

  .gform_validation_container { display:none;  }
  .gform_fields { position:relative; margin: 0; padding-left: 0; }

  .gfield { 

      margin: $input-margin; 

      &.gsection.empty {
          margin:0;
      }

      &:before {
        display:none; 
      }

  
  } 

  .gform_footer { text-align:right; }

  br { display:none; } 

  p { margin:0; }

  .gform_ajax_spinner {
    margin-left: 20px;        /* give it some space from the Submit button */
  }

  .two-column {

      .gform_fields.gform_column {

          width: 49%;
          float: left;
          margin: 0 0%;

          &.gform_column_right {
              float:right;
          }

      }

      .gform_footer {

          float: left;
          width: 100%;
          margin: 0 -2%;

      }
  }

}

.form-wrapper {
    float: left;
    width: 100%;
    padding: 20px 20px;
}




@import 'input-fields';
@import 'radio-buttons';
@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';
