/* Wp GLobal */

html {     font-weight: 400; }

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #999999;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #999999;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #999999;
}

a:hover {
	color:$secondary-color;
}

a.white-text:hover {
	color:$secondary-color !important;
}

form button, 
html form input[type="button"], 
form input[type="reset"], 
form input[type="submit"] {
    float: right;
}

img { max-width: 100%; }

input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=date] {
    -moz-appearance:textfield;
}

.row.input-field {
    margin-bottom: 10px;
}


.alignright {    
	float: right;
	margin-right: 0;
    margin-left: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
}

.alignleft { 
	float:left; 
	margin-left: 0;
	margin-right: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
}

.alignnone  {
	margin-bottom: 15px;
}


.breadcrumb {
	
	color:#FFFFFF;
	font-weight:400;

	a {
		color:#FFFFFF;
		font-weight: 300;

		&:hover{
			color: $link-color;
		}
	}


}

.larger { font-size: 1.4rem; }



.site-header {

    height: 100px;

	.title-area {
		float: left;
	}

	.header-widget-area {
		float: right;
	}

} 

.site-title {

    margin: 0;

	a {
	    display: inline-block;
	    width: 226px;
	    height: 103px;
	    color: transparent;
	    text-indent: -9999px;
	}

}

.wrap,
.happy-fullwidth-layered .row,
.page-template-default .entry-content  {

	max-width: 1600px;
	padding-left: 6.6%;
    padding-right: 6.5%;

    .wrap {
    	padding-left: 0;
    	padding-right: 0;
    }
}

.stripes {
	padding-top:20px;
	padding-bottom:20px;
	margin-bottom: 0;
}

.happy-fullwidth-layered .row .row {
	padding-left: 0;
    padding-right: 0;
    max-width:100%;
}


.home {

	.entry-content  {
		padding-left: 0;
	    padding-right: 0;
	}

	.widget-title {
		display: none;
	}

	.home-odd, .home-even {
		padding-top: 40px;
	    padding-bottom: 30px;
	    float: left;
    	width: 100%;
	}

	.home-section-1.home-odd,
	.home-section-1.home-even {
		padding-top: 0;
	    padding-bottom: 0;
	}

	.home-odd {
 
		background-color:$home-odd-background;

		label, p { color: $off-black; }

	}

	.home-even {
    	background-color: $home-even-background;
	}

}

.stripes.odd {
	background-color:$home-odd-background;
	label, p, h2, h3, h4, h5, h6 { color: #FFFFFF; }
	color: #FFFFFF; 
}

.stripes.even {
	background-color: $home-even-background;
	label, p, h2, h3, h4, h5, h6 { color: $off-black; }
}

nav {
	ul a {
	    text-transform: uppercase;
	}
}


ul.two-column li {
	display:inline-block;
	width:49%;
}

ul.three-cloumn li {
	display:inline-block;
	width:32.33%;
}


.page-template-with-widget-footer {
	
	.entry-content {
    	max-width: 85%;
   	 	margin: 0 auto;
	}

	.page-footer-widget-1 {
		.entry-content {
	    	width: 100%;
	    	max-width: 100%;
		}
	}

}

.site-footer {  

    width: 100%;
    float: left;
    text-align: center;
    font-size: 0.8rem;
    background: #161616;

    .copyright, 
    .copyright a {
    	color:#888888;
    }
}

.yellow-gradient { 
	background: #dfb249;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to right, #dc9f11, #dfb249);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to right, #dc9f11, #dfb249); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.strikethrough {
	background-image: url(../images/text-semi-strike.png);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
} 

.adjust-margin-top, .home .post-23 img.hide-on-small-only {
	margin-top: -70px;
    margin-bottom: -30px;
}

.get-in-touch,
.page-template-with-widget-footer .page-footer-widget-1  {

	background-color:#1a1a1a;
	color:#FFFFFF;
	margin-top: 0;
    padding-bottom: 0;

	.entry-content strong {
	    font-weight: 500;
	    float: left;
	    width: 90px;
	    border: 1px solid #1a1a1a; 
	}

	form {
		margin-top: -30px;
		margin-bottom: -20px;
	}

	.phone {
		color: #FFF;
	}

	h2 {

		color:#FFFFFF; 

		&:before {
			display: none;
		}

	}
		
	label, p { color: #FFFFFF; }

} 

.page-template-with-widget-footer .page-footer-widget-1  {
	padding-bottom: 30px;
}



.single-hero-container {

	color:#FFFFFF;
	text-align:center;
	margin-bottom: 60px;
	height:200px;
	overflow:hidden;
	width: 100%;
	text-shadow: 1px 1px 6px #000;

	h1, h2 {     
		color: #FFFFFF;
	    line-height: 1;
	    text-transform: uppercase;
	    font-size: 2.3rem;
	    margin-bottom: 3px;
	    font-weight: 600;
	    margin: 0;
	}

	.gsps-outer { width: 100%; height:100%; }
	.gsps-inner { width: 100%; }

}

.about-time-clock {

	h2 {
    	margin-top: -20px;
	}

	.page-footer-widget-1 {
		padding-bottom: 25px;
    	padding-top: 5px;
    	margin-top: 100px;
    }

}

.full-width-content-custom {

	p:empty { display:none; }

	h1  {

		position: relative;
		padding-bottom: 30px;

		&:before {
			content: " ";
		    border: 2px solid #3a3a3a;
		    float: left;
		    position: absolute;
		    bottom: 0;
		    width: 60px;
		}
	}


	h2 { 

		&:before { 
			display:none; 
		}


	}


  
	.entry-content {
    	max-width: 100%;
	}

	.section.odd {
		background-color:#f7f7f7; 
	}

} 

.widget-wrap {

	.wrap {
		width: 100%;
	}

}

.contact-us {

	.site-header {
	    position: relative;
	    z-index: 999;
	    background: #FFF;
	}

	.entry-content {

		background: #f7f7f7;
		
		iframe {
	    	border: 0;
	    	margin-top: -125px;
	    	position: relative;
	    	z-index: 222;
		}
	}

	.fa {
		color: #dfb249;
	    font-size: 1.6rem;
	}



	.black-bg { 

		text-align: center;
	    background-color: #3a3a3a;
	    color: #FFF;
	    padding-bottom: 20px;
	    margin-top: -43px;

	    a {

			color: #FFFFFF;
		}
	}

	.wpcf7 {

		p.name, p.phone {
			width: 47%;
		    float: left;
		    margin-right: 10px;
		    display: inline-block;
		}

		p.email, p.message, p.sbmt-btn { width: 96%; }
	}

	input:not([type]), 
	input[type=text]:not(.browser-default), 
	input[type=password]:not(.browser-default), 
	input[type=email]:not(.browser-default), 
	input[type=url]:not(.browser-default), 
	input[type=time]:not(.browser-default), 
	input[type=date]:not(.browser-default), 
	input[type=datetime]:not(.browser-default), 
	input[type=datetime-local]:not(.browser-default), 
	input[type=tel]:not(.browser-default), 
	input[type=number]:not(.browser-default), 
	input[type=search]:not(.browser-default), 
	textarea.materialize-textarea {
	    border: 1px solid #e2e2e2;
	    height: 2.5rem;
	}

	textarea {
	    height: 5rem;
	    height: 6.8rem;
	    border: 1px solid #e2e2e2;
	}

	 h2 {
    	padding-bottom: 0;
	}


}




/****** navigation ***********/

.genesis-nav-menu {

	.menu-item {

		&:hover {
			& > a {

				color: $secondary-color;
			}
		}
	}
}



@media only screen  and (max-width : $medium-screen) {

	.site-header {
		.wrap {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.site-header {
		
		.title-area {
	    	width: 100%;
	    	text-align: center;
		}
	}

	.row .col.s12 {
    	padding-bottom: 25px;
	}

	.page-footer-widget-1  {
		
		overflow: hidden;
		
		.wrap {
    		padding: 0 10px;
		}
	}

	.contact-us .black-bg {
	    margin-top: 0;
	}

	.contact-us .entry-content iframe {
    	margin-top: 0;
	}

	.single-hero-container h1, .single-hero-container h2 {
    	line-height: 2.6rem;
	}

	.get-in-touch form, 
	.page-template-with-widget-footer .page-footer-widget-1 form {
    	margin-top: 0;
    	margin-bottom: 0;
	}

	.get-in-touch, 
	.page-template-with-widget-footer .page-footer-widget-1 {

    	margin-top: 0;
    	padding-bottom: 0;
	}

	nav {
		position: relative;
	}

	.site-header .header-widget-area {
	    float: left;
	    position: relative;
	    left: 0;
	    width: 100%;
	}

	.bullet-two-column li {
    	width: 100%;
	}

}


@media only screen  and (max-width : $small-screen) {

	#menu-primary-menu {

	    float: left;
	    width: 100%;

	    li {
	    	width: 100%;
	    	border-top: 1px solid $primary-color-dark;
	    }
	}

	.nav-primary { display:none; }


}




